import type { ComponentType } from "react"
import React, { useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"
import ButtonButtonFlat from "https://framer.com/m/Button-Button-Flat-xt2S.js@VLoLqzKDNuZUGFISki4o"

// Learn more: https://www.framer.com/docs/guides/overrides/

// const divide =
//     86400000 -
//     (new Date().getUTCHours() * 60 * 60 * 1000 +
//         new Date().getUTCMinutes() * 60 * 1000 +
//         new Date().getUTCSeconds() * 1000)

const useStore = createStore({
    background: "#0099FF",
    // date: new Date(
    //     Date.now() -
    //         new Date().getTimezoneOffset() * 60000 +
    //         60 * 60 * 24 * 1000 -
    //         divide
    // )
    //     .toISOString()
    //     .slice(0, -1),

    // buttonFlat: false,
})

export function idBanner(Component): ComponentType {
    return (props) => {
        return <Component {...props} id="stickyBanner" />
    }
}

export function CountDownOverride(Component): ComponentType {
    return (props) => {
        // const [store, setStore] = useStore()
        const element = useRef(null)

        useEffect(() => {
            // console.log("CountDownOverride")
            // console.log(element)
            // console.log(props)
            // console.log(divide)
            // const tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
            // const date = new Date(Date.now() - tzoffset + 60 * 60 * 24 * 1000)
            //     .toISOString()
            //     .slice(0, -1)
            // console.log(date)
            // console.log("store.date", store.date)
            // console.log("store.date", store.date)
            // setStore({
            //     date: new Date(
            //         Date.now() -
            //             new Date().getTimezoneOffset() * 60000 +
            //             60 * 60 * 24 * 1000 -
            //             divide
            //     )
            //         .toISOString()
            //         .slice(0, -1),
            // })
            // console.log("store.date", store.date)
            // const utcHours = new Date().getUTCHours() * 60 * 60 * 1000
            // const utcMinutes = new Date().getUTCMinutes() * 60 * 1000
            // const utcSeconds = new Date().getUTCSeconds() * 1000
            // var total = utcHours + utcMinutes + utcSeconds
            // total = 86400000 - total
            // localStorage.setItem('date', date);
            // console.log(date.toISOString())
            // element.current.date = store.date
        }, [])

        props.className += " countdown"
        return <Component {...props} ref={element} />
    }
}

export function CountDownParentOverride(Component): ComponentType {
    return (props) => {
        const element = useRef(null)

        useEffect(() => {
            const {
                host,
                hostname,
                href,
                origin,
                pathname,
                port,
                protocol,
                search,
            } = window.location

            // console.log(host)
            // console.log(hostname)
            // console.log(href)
            // console.log(origin)
            // console.log(pathname)
            // console.log(port)
            // console.log(protocol)
            // console.log(search)

            if (!search.includes("Dropshipping_-_Glorify_-_Lifetime")) {
                element.current.style.display = "none"
            }

            if (
                new Date(localStorage.getItem("ISOIn24Hours")).getTime() <
                new Date().getTime()
            ) {
                element.current.style.display = "none"
            }
        }, [])
        props.className += " countdown-parent"
        return <Component {...props} ref={element} />
    }
}

export function BlogArticleClass(Component): ComponentType {
    return (props) => {
        props.className += " blog-article"
        return <Component {...props} />
    }
}

export function input(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

// function findReactComponent(el) {
//     for (const key in el) {
//         if (key.startsWith("__reactInternalInstance$")) {
//             const fiberNode = el[key]

//             return fiberNode && fiberNode.return && fiberNode.return.stateNode
//         }
//     }
//     return null
// }

function useOutsideAlerter(ref) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                // alert("You clicked outside of me!")
                // console.log(ref.current)
                ref.current.style.display = "none"
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])
}

// export function OutsideAlerter(props) {
//   const wrapperRef = useRef(null);
//   useOutsideAlerter(wrapperRef);

//   return <div ref={wrapperRef}>{props.children}</div>;
// }

export function OutsideAlerter(Component): ComponentType {
    return (props) => {
        const wrapperRef = useRef(null)
        useOutsideAlerter(wrapperRef)
        // console.log("this", this, "props: ", props)
        return <Component {...props} ref={wrapperRef} />
    }
}

export function withRotate(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

export function ButtonFlatInHideInOneSec(Component): ComponentType {
    return (props) => {
        // const [store, setStore] = useStore()
        useEffect(() => {
            if (props["data-framer-name"] == "Variant 2") {
                console.log(props)
                console.log(props["data-framer-name"])
                // console.log(123)
                const timer = setTimeout(() => {
                    props.onTap()
                    // setStore({ buttonFlat: true })
                    clearTimeout(timer)

                    // const ticker = setTimeout(() => {
                    // setStore({ buttonFlat: false })
                    // clearTimeout(ticker)
                    // console.log("1")
                    // }, 100)
                    // console.log("2")
                }, 3000)
            }
            // else {
            //     setStore({ buttonFlat: false })
            // }
        }, [props["data-framer-name"]])

        return (
            <>
                <Component {...props} />
            </>
        )
    }
}

export function withHover(Component): ComponentType {
    return (props) => {
        return <Component {...props} whileHover={{ scale: 1.05 }} />
    }
}

export function blogMinHeight(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{ minHeight: "calc(100vh - 104px)" }}
            />
        )
    }
}

export function HideScroll(Component): ComponentType {
    return (props) => {
        props.className += " hide-scroll"
        return <Component {...props} />
    }
}

export function DropdownButton(Component): ComponentType {
    return (props) => {
        props.className += " dropdown-button"
        // props.onTap();
        return <Component {...props} />
    }
}

export function OutOfFixedIsHide(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{ clip: "rect(auto, auto, auto, auto);" }}
            />
        )
    }
}

export function Bottom50VH(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{ bottom: "50vh", top: "auto", position: "fixed" }}
            />
        )
    }
}

export function Top50VH(Component): ComponentType {
    return (props) => {
        return <Component {...props} style={{ top: "50vh" }} />
    }
}

export function MarginTopAuto(Component): ComponentType {
    return (props) => {
        return <Component {...props} style={{ marginTop: "auto" }} />
    }
}

export function EffectHardLight(Component): ComponentType {
    return (props) => {
        return <Component {...props} style={{ mixBlendMode: "hard-light" }} />
    }
}

export function withRandomColor(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                animate={{
                    background: store.background,
                }}
                onClick={() => {
                    setStore({ background: randomColor() })
                }}
            />
        )
    }
}

export function AddBlogCardClassName(Component): ComponentType {
    return (props) => {
        props.className += " blog-card"
        return <Component {...props} />
    }
}

export function AddBlogContentClassName(Component): ComponentType {
    return (props) => {
        props.className += " blog-content"
        return <Component {...props} />
    }
}

export function CopyManuClassName(Component): ComponentType {
    return (props) => {
        props.className += " copy-menu"
        return <Component {...props} />
    }
}

export function AddCollectionBlogClassName(Component): ComponentType {
    return (props) => {
        props.className += " collection-blog"
        return <Component {...props} />
    }
}

export function AddCollectionBlogTitleClassName(Component): ComponentType {
    return (props) => {
        props.className += " collection-blog__title"
        return <Component {...props} />
    }
}

export function NewestLatestChangeOrder(Component): ComponentType {
    return (props) => {
        return <Component {...props} onClick={SwtichLatestNewestButton} />
    }
}

export function ShareFacebookLink(Component): ComponentType {
    return (props) => {
        return <Component {...props} onClick={ShareFacebook} />
    }
}

export function ShareLinkedinLink(Component): ComponentType {
    return (props) => {
        return <Component {...props} onClick={ShareLinkedin} />
    }
}

export function ShareTwitterLink(Component): ComponentType {
    return (props) => {
        return <Component {...props} onClick={ShareTwitter} />
    }
}

export function ShareEmailLink(Component): ComponentType {
    return (props) => {
        return <Component {...props} onClick={ShareEmail} />
    }
}

function ShareFacebook() {
    window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(document.URL) +
            "&t=" +
            encodeURIComponent(document.URL),
        "targetWindow",
        "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250"
    )
    return false
}

function ShareLinkedin() {
    window.open(
        "http://www.linkedin.com/shareArticle?mini=true&url=" +
            encodeURIComponent(document.URL) +
            "&t=" +
            encodeURIComponent(document.URL),
        "targetWindow",
        "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600"
    )
    return false
}

function ShareTwitter() {
    window.open(
        "https://twitter.com/intent/tweet?text=%20Check this out! " +
            encodeURIComponent(document.URL) +
            "&t=" +
            encodeURIComponent(document.URL),
        "targetWindow",
        "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250"
    )
    return false
}

function ShareEmail() {
    window.open(
        "mailto:?subject=" + "&body=" + encodeURIComponent(document.URL)
    )
    return false
}

function SwtichLatestNewestButton(event) {
    console.log(event)
    if (typeof window !== "undefined") {
        var parent = document.querySelector(".collection-blog") //.framer-1ejthpg
        console.log(parent)
        console.log(parent.children)
        var cls = parent.children[0].className
        console.log(cls)
        var childs = document.querySelectorAll("." + cls) //.framer-1xrsnaw-container
        var number = childs.length
        console.log(childs[0])
        if (childs[0].style.order === "") {
            console.log("if")
            for (var i = 0; i < childs.length; i++) {
                console.log(number - i) + ""
                childs[i].style.order = number - i + ""
            }
        } else {
            console.log("else")
            for (var i = 0; i < childs.length; i++) {
                childs[i].style.order = ""
            }
        }
    }
}
